import React from "react";
import "./Projects.css";
import { FaReact, FaHardHat } from "react-icons/fa";
import {
  SiBlockchaindotcom,
  SiHtml5,
  SiMaterialui,
  SiChai,
  SiEthereum,
  SiExpress,
  SiSocketdotio,
  SiTailwindcss,
  SiReactrouter,
  SiJavascript,
  SiMongodb,
  SiNodedotjs,
  SiVercel,
  SiPython
} from "react-icons/si";
import { DiCss3 } from "react-icons/di";
import { VscJson } from "react-icons/vsc";
import { IoLogoJavascript } from "react-icons/io";
export const Projects = () => {
  return (
    <>
      <div className="section">
        <h2 className="section__title different" data-aos="fade-right">
          Projects
        </h2>
        <div className="projects_container">
          <div className="project">
            <div className="project_videocontainer">
              <div data-aos="fade-right">
                <img
                  src="https://cdn.discordapp.com/attachments/1040978542260932631/1144369915869003786/image.png"
                  alt="Snake Game"
                />
              </div>
            </div>
            <div className="project_information" data-aos="fade-right">
              <h2>Snake Game</h2>
              <p>
              A modern take on the classic Snake game using vanilla JavaScript, HTML, and CSS. Leveraging Vercel's serverless functions with MongoDB ensures rapid load times and efficient gameplay. Optimized for a smooth user experience.
              </p>
              <div>
                <SiHtml5 />
                <DiCss3 />
                <IoLogoJavascript />
                <SiMongodb />
                <SiExpress />
                <SiVercel />
              </div>
              <div>
                <a
                  href="https://snake.zettler.dev/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span type="button" className="btn btn--outline onbt">
                    See this Live
                  </span>
                </a>
                <a
                  href="https://github.com/nxtdxve/snake-web"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span type="button" className="btn btn--outline">
                    View Code
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="projects_container">
          <div className="project">
            <div className="project_videocontainer">
              <div data-aos="fade-right">
                <img
                  src="https://cdn.discordapp.com/attachments/1040978542260932631/1145380939166384199/image.png"
                  alt="A.I.O. Sudoku"
                />
              </div>
            </div>
            <div className="project_information" data-aos="fade-right">
              <h2>A.I.O. Sudoku Tool</h2>
              <p>
              The A.I.O. Sudoku Tool, crafted in Python, offers a seamless experience for Sudoku enthusiasts. It can generate, solve, and evaluate puzzles for solvability, making it a versatile companion for both novices and experts. Dive into endless Sudoku challenges with confidence and ease.
              </p>
              <div>
                <SiPython />
              </div>
              <div>
                <a
                  href="https://github.com/nxtdxve/A.I.O-Sudoku"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span type="button" className="btn btn--outline">
                    View Code
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="github-disclaimer" data-aos="fade-right">
        <p>
        For more projects, check out my <a href="https://github.com/nxtdxve" target="_blank" rel="noreferrer">GitHub</a>.
        </p>
      </div>

    
      </div>
    </>
  );
};
