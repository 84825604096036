import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const ThemeContext = React.createContext();

const ThemeProvider = ({ children }) => {
  const [themename, setthemename] = useState("dark");

  const toggletheme = () => {
    setthemename(prevTheme => prevTheme === "light" ? "dark" : "light");
  };

  useEffect(() => {
    document.body.className = themename;
    
  }, [themename]);

  return (
    <ThemeContext.Provider value={[{ themename, toggletheme }]}>
      {children}
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { ThemeProvider, ThemeContext };
