import React from "react";
import Githubcalendar from "react-github-calendar";

export const Github = () => {
  return (
    <>
      <div className="section" data-aos="fade-right">
        <h2 className="section__title different">
          Days I <span className="different"> Code</span>
        </h2>
        <Githubcalendar
          username="nxtdxve"
          blockSize={15}
          blockMargin={5}
          style={{ backgroundColor: 'transparent', color: '#ffffff' }}
          fontSize={16}
        />
      </div>
    </>
  );
};
